
.others{
    background-color: aqua;
}
.menuIcon{
    margin-top: 25px;
    margin-left: 5px;
    cursor: pointer;
    color: rgba(123, 82, 179, 1);
}
