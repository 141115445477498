body {
  background-color: rgba(244, 241, 239, 1);
}
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow:  inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow:  inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(125, 82, 179, 0.5) !important;
}
.textArea {
  height: 400px !important;
}
.rightImage {
  width: 150% !important;
  align-self: center;
}
.rightImage img {
  width: 100%;
}
.leftImage img {
  width: 100%;
}
.leftImage {
  width: 150% !important;
  align-self: center;
}
.aboutUsText {
  text-align: start;
  unicode-bidi: plaintext;
}
.leftImg,
.rightImg {
  width: 90%;
}
.aboutUsTitle {
  text-align:center;
  unicode-bidi: plaintext;
  font-weight: bolder;
}
.btnDesign {
  background: none;
  padding: 5px;
  border: none;
  font-weight: 700;
}
.divider {
  background-color: rgba(125, 82, 179, 1) !important;
  height: 20px;
  width: 1px;
}

.headerTable {
  background-color: white !important;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 10px;
  align-items: center;
}



.descCircle {
  width: 32px;
  height: 32px;
  background-color: rgba(123, 82, 179, 1);
  border-radius: 50%;
  align-items: center;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 0;
  align-self: center;
}
.icon {
  color: white;
  width: 18px !important;
  cursor: pointer;
}
.smallBg{
    background-color: rgba(255, 226, 226, 1);
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(69, 30, 0, 1);
    font-weight: 600;
}


.profilePic {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border-top: 3px RGB(255, 185, 62) solid;
  border-right: 3px RGB(255, 185, 62) solid;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.userShow {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.whiteBg {
  width: 100%;
  height: 80%;
  background-color: white !important;
  box-shadow: 0px 4px 90px rgb(163 171 185 / 24%);
  border-radius: 10px;
  align-items: center;
  padding: 5px;
}
.nameText::first-letter {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: large;
}
.formContainerPro {
  background-color: white;
  padding-top: 30px;
  padding-bottom: 70px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0px 2px 0px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.hide {
  display: none;
  color: rgba(0, 0, 0, 0.6);
}
.required {
  margin-top: 5px;
  color: red;
  font-size: 20px;
  margin-left: 3px;
  cursor: pointer;
}
.required:hover + .hide {
  display: block;
}
.visibile {
  top: 17%;
  left: 90%;
  color: rgba(123, 82, 179, 1);
  cursor: pointer;
}
.loadingIcon {
  color: white !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: 8px !important;
}
.filter {
  cursor: pointer;
}
.filterBtn {
  background-color: rgba(125, 82, 179, 1) !important;
  width: 75px !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}
.reload {
  cursor: pointer;
  color: RGB(0, 124, 0) !important;
  font-size: 25px !important;
}
.filterIcon {
  cursor: pointer;
  font-size: 25px !important;
}
.describeDel,
.describeFilter {
  display: none;
  cursor: pointer;
  transition: 0.3s;

}
.delForever:hover .describeDel,
.filerDiv:hover .describeFilter {
  display: flex;
}
.imgStyle{
  width: 150px;
  height: 120px;
}


.backBtn:hover {
  border: 1px solid #1976d2;
}
.delete:hover{
  border: 1px solid #dc3545;

}


.textarea{
  resize: none;
  height: fit-content !important;
}

.quizImg {
  width: 100px;
  height: 70px;
}
.achieveImg{
  width: 105px;
  height: 120px;
}
.closeButtn {
  position: absolute;
  background-color: rgba(255, 80, 80, 1);
  width: 24px;
  height: 24px;
  top: 8px;
  left: 92%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  z-index: 99;
}

h6 {
  margin-top: 15px;
  margin-bottom: 15px;
  
  font-weight: 500;
  line-height: 0;
  color: rgba(123, 82, 179, 1);
}


.img{
  width: 70px;
  height: 70px;

}
.imgSkin{
  width: 90px;
  height: 90px;
}
.addBtn {
  background-color: rgba(125, 82, 179, 1) !important;
  width: 125px !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.addBtn:hover {
  color: white !important;
}

.form-control:focus {
  border: 1px solid rgba(125, 82, 179, 1) !important;
  box-shadow: 0 0 3px rgba(125, 82, 179, 1) !important;
}
.closeBtn {
  position: absolute;
  background-color: rgba(255, 80, 80, 1);
  width: 24px;
  height: 24px;
  top: -10px;
  right: -6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}
.form-select:focus {
  border: 1px solid rgba(125, 82, 179, 1) !important;
  box-shadow: 0 0 3px rgba(125, 82, 179, 1) !important;
}


.flag {
  width: 50px;
  height: 45px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 10px, rgba(0, 0, 0, 0.23) 0px 4px 4px;
}
.delIcon {
  color: #ff5050;
    cursor: pointer;
  font-size: 18px !important;
}
.viewIcon {
  color: #729fff;
  cursor: pointer;
  font-size: 18px !important;
}
.editIcon {
  font-size: 18px !important;
  cursor: pointer;
  color: rgba(224, 132, 29, 1);
}


.closeIcon {
  width: 18px !important;
  cursor: pointer;
}

.imgstyle{
  width: 150px;
  height: 120px;
}


.mAnsImg{
  width: 70px;
  height: 70px;
}


.btnStyle {
  background-color: rgba(123, 82, 179, 1) !important;
  color: white;
  width: 100px;
}

.btnLogout {
  /* background-color: rgba(123, 82, 179, 1) !important; */
  color:  rgba(123, 82, 179, 1) !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
}


.closeButn {
  position: absolute;
  background-color: rgba(255, 80, 80, 1);
  width: 24px;
  height: 24px;
  top: 8px;
  left: 96%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  z-index: 99;
}
.quizSelect {
  width: 500px;
}
.downIcon {
  cursor: pointer;
}
.dropDown {
  background-color: white;
  width: 100%;
}
.checkBoxx {
  border-color: blueviolet !important;
}
.selectDesign:focus {
  border: none !important;
}
.customSelect {
  height: 40px !important;
}


.delIcon {
  color: #ff5050;
    cursor: pointer;

  font-size: 18px !important;
}
.viewIcon {
  color: #729fff;
  cursor: pointer;
  font-size: 18px !important;
}
.editIcon {
  font-size: 18px !important;
  cursor: pointer;
  color: rgba(224, 132, 29, 1);
}

.deleteForeverDiv {
  display: flex;
  justify-content: end;
  margin-right: 10px;
}

.deleteForever {
  color: #ff5050;
  cursor: pointer;
  font-size: 24px !important;
}
.description {
  align-self: center;
  font-weight: 700;
  margin-left: 5px;
}

.btn-outline:hover{
  color:white !important;
  outline: none !important;

}
.trick{
  font-size: 100px;
  font-weight: 900;
color: #A652B3;
}
.ddd {
  width: auto;
  height: auto;
  background-color: none;
  position: relative;
  animation-name: example;
  animation-duration: 6s;
}

@keyframes example {
  0%   {background-color:white; left:0px; top:0px;}
  25%  {background-color:white; left:200px; top:0px;}
  50%  {background-color:white; left:200px; top:200px;}
  75%  {background-color:white; left:0px; top:200px;}
  100% {background-color:white; left:0px; top:0px;}
}