.headerTable {
  background-color: white !important;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
  border-radius: 10px;
  align-items: center;
}
* {
  margin: 0;
  padding: 0;
}

h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 0;
  color: rgba(123, 82, 179, 1);
}
.descCircle {
  width: 32px;
  height: 32px;
  background-color: rgba(123, 82, 179, 1);
  border-radius: 50%;
  align-items: center;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 0;
  align-self: center;
}
.icon {
  color: white;
  cursor: pointer;
}
.smallBg{
    background-color: rgba(255, 226, 226, 1);
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(69, 30, 0, 1);
    font-weight: 600;
}
