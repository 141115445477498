.topBar {
  display: flex;
  justify-content: end;
  margin-right: 3px;
}
.topText::first-letter {
  color: rgba(123, 82, 179, 1);
  text-transform: uppercase;
  font-weight: bolder;
  font-size: large;
}
.clear {
  width: 150px;
}
.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top: 2px RGB(255, 185, 62) solid;
  border-right: 2px RGB(255, 185, 62) solid;
  cursor: pointer;
}
