@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
.styledBorder{
    border: white 1px solid !important;
    color:white !important;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.styledBorder::placeholder {
    color: white;
    opacity: 1; 
  }
  

.x{
    display: grid;
    justify-content: center;
    align-content: center;
}

.back {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #7f52b3 !important;
  }
  
  .div-center {
    width: 400px;
    height: 400px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 1em 2em;
    display: table;
  }
  
  div.content {
    display: table-cell;
    vertical-align: middle;
  }
  
  .loginBtn {
    background-color: white !important;
    border: none !important;
    color: #7f52b3 !important;
  }
  .input-field {
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid white;
    color: white;
    background-color: none !important;
  }
  
  .form-group input {
    border: none;
    outline: none;
    box-shadow: none;
    width: 100%;
    padding: 0px 2px;
    background-color: none !important;
  }
  .formStyle input {
    background-color: transparent !important;
  }
  ::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }
  input{
    color: white;
  }
  .loading{
    color: #7f52b3 !important;
    width: 20px !important;
    height: 20px !important;
  }
  .smallLogin{
    width: 60px !important;
  }