* {
  margin: 0;
  padding: 0;
}

.sideBar {
  background: linear-gradient(180deg, #7952b3 0%, #b152b3 100%, #b152b3 100%);
  color: white;
  border-radius: 0px 80px 0px 0px;
  width: 240px;
  padding-bottom: 10px;
  height: 100%;
  min-height: 100vh;

}
.imgSide {
  width: 120px;
  margin-top: 30px;
}
.sideBarList {
  list-style: none;
  padding: 0;
}
.sideBarList .sideBarListItem {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 4px;
  position: relative;
}
Link {
  cursor: pointer;
  padding: 4px;
  position: relative;
}
.active {
  background-color: #a672f2 !important;
  border-radius: 5px !important;
  display: inline-block;
  width: 101%;
  padding-bottom: 5px;
  padding-top: 5px;
  transition: all 0.3s ease-in;
}
.active .activeIcon {
  color: rgba(255, 193, 7, 1);
}
.subList a {
  color: white;
  text-decoration: none;
}

/* .sideBarListItem :hover{
  background-color: #a672f2 !important;
  border-radius: 5px!important;
} */

.forward {
  font-size: 18px !important;
  font-weight: 600;
  position: absolute;
  right: 10px;
}
.subList {
  padding-top: 5px;
  padding-bottom: 0;
  list-style: none;
}
.subList li .active {
  font-weight: 700;
  padding-left: 2px;
}

a,
a:hover {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 600px) {
  .sideBar {
    display: none;
  }
}
